<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-09-19 14:50:43
 * @LastEditors: xiaoq
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <!-- <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <p class="footerLine">© 2024 Zonaplata Latamxn, S.A.P.I. de C.V., SOFOM, E.N.R.</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/sichuanyuanduntechnology/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  /* padding: 0 220px; */
  /* margin-bottom: 80px; */
}

.img2Box {
  background-color: #5E008A;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.footerLine {
  color: #FFFFFF;
  font-size: 20px;
}
</style>
